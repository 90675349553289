<template>
  <div class="flex relative" :class="{ 'rounded-full': rounded }">
    <UserFlag
      v-if="country"
      :code="country"
      class="absolute -top-[3px] -right-[3px]"
      :class="flagClass"
    />
    <div class="object-cover" :class="dimensions">
      <img
        class="h-full w-full"
        :class="{ 'rounded-full': rounded, [ringClasses]: isSalonRougePlayer }"
        :src="url"
        loading="lazy"
        height="32"
        width="32"
        decoding="async"
      >
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  url: {
    type: String,
    required: true,
  },
  country: {
    type: String,
    default: null,
  },
  flagClass: {
    type: String,
    default: '',
  },
  size: {
    type: String,
    default: 'xs',
    validator(value) {
      return [
        '2xs',
        'xs',
        'sm',
        'md',
        'lg',
        'xl',
        '2xl',
        '3xl',
        'full',
      ].includes(value);
    },
  },
  rounded: {
    type: Boolean,
    default: true,
  },
  level: {
    type: Number,
    default: 0,
  },
});

const isSalonRougePlayer = computed(() => props.level === 2);

const dimensions = computed(() => {
  const classes = {
    '2xs': 'h-3 w-3',
    xs: 'h-6 w-6',
    sm: 'h-8 w-8',
    md: 'h-10 w-10',
    lg: 'h-12 w-12',
    xl: 'h-14 w-14',
    '2xl': 'h-28 w-28',
    '3xl': 'h-32 w-32',
  };

  return classes[props.size];
});

const ringClasses = computed(() => {
  const ringSizeClasses = {
    '2xs': 'ring-1',
    xs: 'ring-1',
    sm: 'ring-2',
    md: 'ring-4',
    lg: 'ring-4',
    xl: 'ring-4',
    '2xl': 'ring-4',
    '3xl': 'ring-4',
  };

  return `${ringSizeClasses[props.size]} ring-red-600 ring-opacity-80`;
});
</script>
