<template>
  <span v-if="visible" class="shadow-black text-shadow-xs">
    {{ flag }}
  </span>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/store/user';
import { useUiStore } from '@/store/ui';

const props = defineProps({
  code: {
    type: String,
    default: null,
  },
});

const userStore = useUserStore();
const { userData, } = storeToRefs(userStore);

const uiStore = useUiStore();
const { showUserFlags, } = storeToRefs(uiStore);

const visible = computed(() => {
  if (!props.code || !userData.value || !userData.value.metadata?.showUserCountry) {
    return false;
  }

  return showUserFlags.value?.toString() === 'true';
});
const flag = computed(() => getFlagEmoji(props.code));

function getFlagEmoji(countryCode) {
  const codePoints = countryCode
  .toUpperCase()
  .split('')
  .map(char => 127397 + char.charCodeAt(0));
  return String.fromCodePoint(...codePoints);
}
</script>
